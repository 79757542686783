import reject from "ramda/src/reject";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import lineItemFilter from "./TaxLineItemFilter";

export default bill => ({
  id: bill.id,
  contact: {
    id: bill.contact.id
  },
  currency: {
    id: bill.currency.id
  },
  reference: bill.reference,
  issuedDate: bill.issuedDate,
  dueDate: bill.dueDate,
  exchangeRate: bill.exchangeRate,
  lineItems: reject(
    li => isEmpty(li.description) || isNil(li.description),
    bill.lineItems.map(li => lineItemFilter(li))
  )
});
