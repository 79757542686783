<template lang="pug">
  .bill
    project-facade(v-if="bill", active-tab="bill", :project-id="bill.project.id")
    bill-form(
      v-model='bill',
      :loading='$apollo.loading',
      :saving='saving',
      submit-label='Update bill',
      @save='updateBill',
      @cancel='cancelUpdate')
</template>

<script>
import updateBillMutation from "@/graphql/mutations/finance/bills/UpdateBill.gql";
import updateBillFilter from "@/graphql/mutations/filters/UpdateBillFilter";
import mutate from "@/components/utils/Mutator";
import BillQuery from "@/graphql/queries/finance/bills/Bill.gql";
import BillForm from "./Form.vue";
import ProjectFacade from "@/components/finance/project_facade/ProjectFacade.vue";

export default {
  apollo: {
    bill: {
      query: BillQuery,
      variables() {
        return { id: this.$route.params.id };
      }
    }
  },
  components: {
    BillForm,
    ProjectFacade
  },
  data() {
    return {
      bill: null,
      saving: false
    };
  },
  methods: {
    updateBill() {
      const { project } = this.bill;
      const vm = this;

      this.saving = true;

      return mutate(this, {
        mutation: updateBillMutation,
        variables: {
          input: {
            ...updateBillFilter(this.bill)
          }
        }
      })
        .then(data => {
          window.location.assign(
            `/projects/${project.id}/bills/${data.data.updateBill.bill.id}`
          );
        })
        .then(() => {
          vm.saving = false;
        });
    },
    cancelUpdate() {
      window.location.assign(
        `/projects/${this.bill.project.id}/bills/${this.bill.id}`
      );
    }
  }
};
</script>
